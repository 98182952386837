import React, { } from "react"
import "../../../assets/styles/_index.scss"
import Favicon from "react-favicon"
import AarraProperty from "../../../components/developers/aarradeveloper/ourproperties/AarraProperty";

const favicon_logo = require("../../../images/favicon.png");

const AarraPropertyPage = () => (
  <>
    <Favicon url={favicon_logo} />

    <AarraProperty />
  </>
)

export default AarraPropertyPage
